<template>
    <v-container>
        <v-row class="d-flex">
            <v-btn @click="$router.go(-1)" class="mt-3">
                <v-icon>mdi-arrow-left</v-icon>
                Back
            </v-btn>
        </v-row>
        <v-row>
            <v-col cols="12">
                <full-wizard
                    v-if="wizard.id"
                    :wizard="wizard"
                    :isOwner="isOwner"
                    :activeOffer="activeOffer"
                ></full-wizard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import FullWizard from "../components/assets/wizards/full-wizard";
import {apiServiceVue} from "../services/apiService.vue";
import MarketService from "@/services/marketService";
import {AssetType} from "@/classes/asset/AssetType";
import {OfferStatus} from "@/classes/market/OfferStatus";

export default {
    components: {FullWizard},
    beforeRouteUpdate(to, from, next) { // for smooth refresh page when changing wizard
        this.wizardId = parseInt(to.params.id)
        this.wizard = {};
        this.init();
        next();
    },
    data() {
        return {
            marketService: undefined,
            wizardId: parseInt(this.$route.params.id),
            wizard: {},
            wizardOffers: [],
        };
    },
    mounted: function () {
        this.init();
        window.addEventListener("reload-view", this.init);
    },
    destroyed() {
        window.removeEventListener("reload-view", this.init);
    },
    computed: {
        activeOffer() {
            return this.wizardOffers
                .find(offer => offer.status === OfferStatus.AVAILABLE)
        },
        web3() {
            return this.$store.state.web3;
        },
        isOwner() {
            return Boolean(
                this.isWalletConnected
                &&
                (
                    this.wizard.owner?.toLowerCase() === this.web3.address?.toLowerCase()
                    ||
                    this.activeOffer?.creator.toLowerCase() === this.web3.address?.toLowerCase()
                )
            );
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        }
    },
    methods: {
        init() {
            this.marketService = new MarketService();
            this.getWizard(this.wizardId);
            this.getAssetOffers();
        },
        getWizard(id) {
            const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'many/' + id;
            apiServiceVue.get(url).then(res => {
                if (res.data) {
                    this.wizard = res.data.at(0);
                }
            });
        },
        getAssetOffers() {
            this.wizardOffers = [];
            this.marketService.getOffersFor(AssetType.WIZARD, this.wizardId)
                .then(offers => this.wizardOffers = offers);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
